// extracted by mini-css-extract-plugin
export var container = "WhatSkillAndMeritSection-module--container--1I3kh";
export var inner = "WhatSkillAndMeritSection-module--inner--GarwF";
export var paragraph1 = "WhatSkillAndMeritSection-module--paragraph1--1W1Nx";
export var boxes = "WhatSkillAndMeritSection-module--boxes--176xP";
export var box = "WhatSkillAndMeritSection-module--box--1wNdy";
export var boxTitle = "WhatSkillAndMeritSection-module--boxTitle--3Ooni";
export var jobListContainer = "WhatSkillAndMeritSection-module--jobListContainer--1-IuK";
export var checkIcon = "WhatSkillAndMeritSection-module--checkIcon--3iRYY";
export var qualificationContainer = "WhatSkillAndMeritSection-module--qualificationContainer--QRPbW";
export var qualificationItem = "WhatSkillAndMeritSection-module--qualificationItem--170JU";
export var qualificationNote = "WhatSkillAndMeritSection-module--qualificationNote--1Td8y";