import clsx from 'clsx'
import * as style from './WhatWeCanDoBox.module.scss'

type Props = {
  children: React.ReactNode
  className?: string
  iconClassName?: string
}

const WhatWeCanDoBox = (props: Props) => {
  return (
    <div className={clsx(style.box, props.className)}>
      <div>{props.children}</div>
    </div>
  )
}

export default WhatWeCanDoBox
