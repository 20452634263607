import * as style from './FAQSection.module.scss'
import QIcon from '@/assets/svg/vietnam/question.svg'
import AIcon from '@/assets/svg/vietnam/answer.svg'

const FAQSection = () => {
  return (
    <section className={style.container}>
      <h4 className={style.title}>よくあるご質問</h4>
      <div className={style.content}>
        <dl className={style.questionBox}>
          <dt className={style.questionTitleContainer}>
            <QIcon className={style.icon} />
            <p className={style.questionTitle}>
              人材紹介に関する
              <br className={style.hidePc} />
              料金はどれくらいですか？
            </p>
          </dt>
          <dd className={style.questionBody}>
            <AIcon className={style.icon} />
            <p>
              職種やビザ、また受入人数と国によって違います。一度ヒアリングさせて頂けますと、正確な見積もりができます。お気軽にお問い合わせください。
            </p>
          </dd>
        </dl>
        <dl className={style.questionBox}>
          <dt className={style.questionTitleContainer}>
            <QIcon className={style.icon} />
            <p className={style.questionTitle}>外国人材の国籍は？</p>
          </dt>
          <dd className={style.questionBody}>
            <AIcon className={style.icon} />
            <p>
              当社では勤勉な国と言われているベトナムや英語が堪能な人材が多い東南アジア諸国を中心とした人材をご紹介しております。
            </p>
          </dd>
        </dl>
        <dl className={style.questionBox}>
          <dt className={style.questionTitleContainer}>
            <QIcon className={style.icon} />
            <p className={style.questionTitle}>
              特定技能外国人は週に何時間働けますか？
            </p>
          </dt>
          <dd className={style.questionBody}>
            <AIcon className={style.icon} />
            <p>
              40時間働く事が出来ます。働き方改革の一環として労働基準法が改正されましたが、時間外労働の上限に関する規制は当然「特定技能」外国人にも適用されます。時間外労働の上限は、原則月45時間で年360時間となります。
            </p>
          </dd>
        </dl>
      </div>
    </section>
  )
}

export default FAQSection
