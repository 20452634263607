import { StaticImage } from 'gatsby-plugin-image'
import * as style from './WhatWeCanDoSection.module.scss'
import WhatWeCanDoBox from './WhatWeCanDoBox'

const WhatWeCanDoSection = () => {
  return (
    <section className={style.container}>
      <h3 className={style.title}>受入れに必要な支援業務もお任せください</h3>
      <p className={style.paragraph}>
        特定技能外国人労働者を受け入れる際には、様々な支援業務が義務化されています。当社はそのすべての支援業務を受入企業様に代わってサポートいたします。
      </p>
      <div className={style.boxes}>
        <WhatWeCanDoBox className={style.box}>
          <StaticImage
            className={style.icon}
            objectFit="contain"
            alt="what_we_can_do_1"
            src="../../../assets/img/vietnam/what_we_can_do_1.png"
            quality={100}
            width={130}
          />
          <p className={style.boxText}>※前ガイダンスの実施</p>
        </WhatWeCanDoBox>
        <WhatWeCanDoBox className={style.box}>
          <StaticImage
            className={style.icon}
            objectFit="contain"
            alt="what_we_can_do_2"
            src="../../../assets/img/vietnam/what_we_can_do_2.png"
            quality={100}
            width={130}
          />
          <p className={style.boxText}>出入国する際の送迎</p>
        </WhatWeCanDoBox>
        <WhatWeCanDoBox className={style.box}>
          <StaticImage
            className={style.icon}
            objectFit="contain"
            alt="what_we_can_do_3"
            src="../../../assets/img/vietnam/what_we_can_do_3.png"
            quality={100}
            width={130}
          />
          <p className={style.boxText}>
            適正な住居の確保・生活に必要な契約の支援
          </p>
        </WhatWeCanDoBox>
        <WhatWeCanDoBox className={style.box}>
          <StaticImage
            className={style.icon}
            objectFit="contain"
            alt="what_we_can_do_4"
            src="../../../assets/img/vietnam/what_we_can_do_4.png"
            quality={100}
            width={130}
          />
          <p className={style.boxText}>生活オリエンテーションの実施</p>
        </WhatWeCanDoBox>
        <WhatWeCanDoBox className={style.box}>
          <StaticImage
            className={style.studyBoxIcon}
            objectFit="contain"
            alt="what_we_can_do_5"
            src="../../../assets/img/vietnam/what_we_can_do_5.png"
            quality={100}
            width={130}
          />
          <p className={style.boxText}>
            日本語学習の
            <br />
            機会の提供
          </p>
        </WhatWeCanDoBox>
        <WhatWeCanDoBox className={style.box}>
          <StaticImage
            className={style.icon}
            objectFit="contain"
            alt="what_we_can_do_6"
            src="../../../assets/img/vietnam/what_we_can_do_6.png"
            quality={100}
            width={130}
          />
          <p className={style.boxText}>
            相談
            <br />
            または苦情への対応
          </p>
        </WhatWeCanDoBox>
        <WhatWeCanDoBox className={style.box}>
          <StaticImage
            className={style.icon}
            objectFit="contain"
            alt="what_we_can_do_7"
            src="../../../assets/img/vietnam/what_we_can_do_7.png"
            quality={100}
            width={130}
          />
          <p className={style.boxText}>日本人との交流促進</p>
        </WhatWeCanDoBox>
        <WhatWeCanDoBox className={style.box}>
          <StaticImage
            className={style.icon}
            objectFit="contain"
            alt="what_we_can_do_8"
            src="../../../assets/img/vietnam/what_we_can_do_8.png"
            quality={100}
            width={130}
          />
          <p className={style.boxText}>定期的な面談の実施・行政機関への通報</p>
        </WhatWeCanDoBox>
      </div>
      <p className={style.note}>
        ※外国人が十分に理解出来る（母国語）で実施することが義務化されています。
      </p>
    </section>
  )
}

export default WhatWeCanDoSection
