import * as style from './UsageFlowSection.module.scss'
import Icon from '@/assets/svg/vietnam/line.svg'

const UsageFlowSection = () => {
  return (
    <section className={style.container}>
      <h4 className={style.title}>ご利用の流れ</h4>
      <ul className={style.usageListContainer}>
        <li className={style.usageItem}>
          <dl>
            <dt className={style.usageTitle}>
              <span className={style.usageNo}>1</span>お問い合わせ
            </dt>
            <dd className={style.usageBody}>
              人材や料金など、まずはお気軽にお問い合わせください。
            </dd>
          </dl>
          <Icon className={style.underlineIcon} />
        </li>
        <li className={style.usageItem}>
          <dl>
            <dt className={style.usageTitle}>
              <span className={style.usageNo}>2</span>ヒアリング/ご紹介
            </dt>
            <dd className={style.usageBody}>
              募集人数、時期、業務内容、給料などをおたずねします。直接おうかがいするほか、ZOOM等の様々なリモートミーティングも可能です。その後、お客様へ最適な人材をご紹介いたします。
            </dd>
          </dl>
          <Icon className={style.underlineIcon} />
        </li>
      </ul>
    </section>
  )
}

export default UsageFlowSection
