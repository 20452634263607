import { StaticImage } from 'gatsby-plugin-image'
import * as style from './StrengthsSection.module.scss'

const StrengthsSection = () => {
  return (
    <section className={style.container}>
      <h3 className={style.title}>
        ティーオーピー・スタッフ
        <span className={style.underline}>
          <span className={style.strong}>3</span>つの強み
        </span>
      </h3>
      <div className={style.boxContainer}>
        <div className={style.box}>
          <label className={style.boxLabel}>人材紹介</label>
          <StaticImage
            alt="mv"
            src="../../../assets/img/vietnam/strength_1.jpg"
            width={630}
          />
          <p className={style.boxParagraph}>
            当社ではベトナムなどの東南アジアを諸国を中心に、企業様のニーズに応じた即戦力となる人材をご紹介させて頂きます。
          </p>
        </div>
        <div className={style.box}>
          <label className={style.boxLabel}>登録手続き代行サポート</label>
          <StaticImage
            alt="mv"
            src="../../../assets/img/vietnam/strength_2.jpg"
            width={630}
          />
          <p className={style.boxParagraph}>
            ご紹介後は手続きに必要な書類作成をTOPで一部代行、
            企業様には最低限の業務で外国人材をご採用
            頂けるため、手間がかかりません。
          </p>
        </div>
        <div className={style.box}>
          <label className={style.boxLabel}>外国人生活支援サポート</label>
          <StaticImage
            alt="mv"
            src="../../../assets/img/vietnam/strength_3.jpg"
            width={630}
          />
          <p className={style.boxParagraph}>
            特定技能外国人を採用するためには、職業上だけでなく
            社会生活での支援計画の作成や、様々な支援業務の実施が
            義務化されています。TOPでは企業様で受け入れた外国
            人材が戦力となり、快適に日本で生活できるよう、外国人
            生活の支援も一気通貫でフルサポートいたします。
          </p>
        </div>
      </div>
    </section>
  )
}

export default StrengthsSection
