import * as style from './WhatSkillAndMeritSection.module.scss'
import DogEarHeading from './components/DogEarHeading'
import MeritSection from './MeritSection'
import Icon from '@/assets/svg/vietnam/check.svg'

const WhatSkillAndMeritSection = () => {
  return (
    <div className={style.container}>
      <section className={style.inner}>
        <DogEarHeading>特定技能とは</DogEarHeading>
        <p className={style.paragraph1}>
          深刻化する人材不足へ対応するために2019年4月からスタートした、―定の専門性・技能を有し即戦力となる外国人材を幅広く受け入れるための新しい制度です。
        </p>

        <div className={style.boxes}>
          <div className={style.box}>
            <p className={style.boxTitle}>特定技能で外国人が働ける業種</p>
            <ul className={style.jobListContainer}>
              <li>
                <Icon className={style.checkIcon} />
                介護（訪問介護はNG）
              </li>
              <li>
                <Icon className={style.checkIcon} />
                宿泊（ホテルや旅館）
              </li>
              <li>
                <Icon className={style.checkIcon} />
                外食（飲食店全般）
              </li>
              <li>
                <Icon className={style.checkIcon} />
                飲食料品製造
              </li>
              <li>
                <Icon className={style.checkIcon} />
                建設（11の作業区分のみOK）
              </li>
              <li>
                <Icon className={style.checkIcon} />
                ビルクリーニング
              </li>
              <li>
                <Icon className={style.checkIcon} />
                自動車整備
              </li>
              <li>
                <Icon className={style.checkIcon} />
                農業
              </li>
              <li>
                <Icon className={style.checkIcon} />
                漁業
              </li>
              <li>
                <Icon className={style.checkIcon} />
                素形材産業
              </li>
              <li>
                <Icon className={style.checkIcon} />
                産業機械製造業
              </li>
              <li>
                <Icon className={style.checkIcon} />
                電気・電子情報関連産業
              </li>
              <li>
                <Icon className={style.checkIcon} />
                航空
              </li>
              <li>
                <Icon className={style.checkIcon} />
                造船・舶用工業
              </li>
            </ul>
          </div>
          <div className={style.box}>
            <p className={style.boxTitle}>在留資格</p>
            <div className={style.qualificationContainer}>
              <ul>
                <li className={style.qualificationItem}>
                  <p>【在留期間】</p>
                  <p>
                    上限５年
                    <span className={style.qualificationNote}>
                      （4ヵ月、6ヵ月、又は1年ごとの更新）
                    </span>
                  </p>
                </li>
                <li className={style.qualificationItem}>
                  <p>【技能水準】</p>
                  <p>試験等で確認※</p>
                </li>
                <li className={style.qualificationItem}>
                  <p>【日本語能力水準】</p>
                  <p>生活や業務に必要な日本語能力を試験等で確認※</p>
                </li>
                <li className={style.qualificationItem}>
                  <p>【家族の帯同】</p>
                  <p>原則認められない</p>
                </li>
              </ul>
              <p>
                受け入れ機関、または登録支援機関による
                支援実施義務の対象となる。
              </p>
              <p className={style.qualificationNote}>
                ※技能実習2号を良好に修了した者は試験等免除
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={style.inner}>
        <DogEarHeading>エンジニア（高度人材）とは</DogEarHeading>
        <p className={style.paragraph1}>
          高度な教育を受け、スキルと知識を身に着けたＩＴ技術者、建築・製造技術者、英語教師などこれからの産業界にイノベーションをもたらすとともに日本の発展や効率性を高めることが期待される人材で
          す。
        </p>

        <div className={style.boxes}>
          <div className={style.box}>
            <p className={style.boxTitle}>エンジニアとして外国人が働ける業種</p>
            <ul className={style.jobListContainer}>
              <li>
                <Icon className={style.checkIcon} />
                通訳・翻訳
              </li>
              <li>
                <Icon className={style.checkIcon} />
                webデザイン
              </li>
              <li>
                <Icon className={style.checkIcon} />
                システムエンジニア・プログラマー
              </li>
              <li>
                <Icon className={style.checkIcon} />
                機械メンテナンス
              </li>
              <li>
                <Icon className={style.checkIcon} />
                電子・電気技術・メカトロ技術者
              </li>
              <li>
                <Icon className={style.checkIcon} />
                土木・建設設計
              </li>
              <li>
                <Icon className={style.checkIcon} />
                自動車整備士
              </li>
              <li>
                <Icon className={style.checkIcon} />
                工場内・機械オペレーター
              </li>
            </ul>
          </div>

          <div className={style.box}>
            <p className={style.boxTitle}>在留資格</p>
            <div className={style.qualificationContainer}>
              <ul>
                <li className={style.qualificationItem}>
                  <p>【在留期間】</p>
                  <p>
                    ５年
                    <span className={style.qualificationNote}>
                      ※高度専門職2号は、在留期間が無期限
                    </span>
                  </p>
                </li>
                <li className={style.qualificationItem}>
                  <p>【技術水準・日本語能力水準】</p>
                  <p>
                    入国管理局が定める高度人材ポイント制度で審査。
                    スキルや実績が合計70ポイント以上で高度人材と認定される。
                  </p>
                </li>
                <li className={style.qualificationItem}>
                  <p>【永住許可要件の緩和】</p>
                  <p>3年在留で永住許可申請が可能</p>
                </li>
                <li className={style.qualificationItem}>
                  <p>【配偶者の就労】</p>
                  <p>
                    高度人材の配偶者は「技術・人文知識・国際業務」や「教育」「研究」「興行」という在留資格に該当する活動限定で、それらの在留資格を取得していない場合でも活動することが可能です。
                  </p>
                </li>
                <li className={style.qualificationItem}>
                  <p>【親の帯同】</p>
                  <p>
                    年収や子供の年齢など一定の条件はありますが、親を連れてくることが可能です。
                  </p>
                </li>
                <li className={style.qualificationItem}>
                  <p>【入国・在留手続の優先処理】</p>
                  <p>
                    当社では勤勉な国と言われているベトナムや英語が堪能な人材が多いフィリピンなど、東南アジア諸国を中心とした人材をご紹介しております。
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <MeritSection />
    </div>
  )
}

export default WhatSkillAndMeritSection
