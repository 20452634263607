import { StaticImage } from 'gatsby-plugin-image'
import * as style from './MainVisualSection.module.scss'

const MainVisualSection = () => {
  return (
    <section className={style.container}>
      <StaticImage
        className={style.mvContainer}
        alt="mv"
        src="../../../assets/img/vietnam/mv.png"
        quality={100}
        width={1920}
      />
      <div className={style.paragraphContainer}>
        <h3 className={style.title}>外国人材採用をお考えの企業様へ</h3>
        <div className={style.paragraph}>
          <p>
            国内の少子高齢化にともない、日本で働く外国人労働者数は165万人を突破。(2019年10月時点）7年連続で増え過去最高を更新しました。日本の働き手不足により、外国人の雇用は当たり前の時代となっています。
            <br />
            これまで人材の送り出し国であったアジアの国でも高齢化社会に向かう国が増える中、人材確保は年々難しくなっています。介護人材やIT人材を中心としたグローバルな人材獲得競争が激化する中、出遅れている企業は危機感を持たなければいけません。外国人雇用に取り組まなければ、採用や人材定着のノウハウが蓄積されず、将来的に外国人材が必要になった時には、自社が望むレベルの人材を確保できない可能性が高いといえます。「すぐに採用」まで至らなくとも、手遅れになる前に早く動き出すべきでしょう。
            ティーオーピースタッフではこれまで培った人材支援のノウハウを活かし、各国の「エンジニア」や「特定技能」の採用における必要な手続きやアフターフォローを一気通貫でサポートいたします。
          </p>
        </div>
      </div>
    </section>
  )
}

export default MainVisualSection
