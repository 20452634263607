import * as style from './DogEarHeading.module.scss'

type Props = {
  children: React.ReactNode
}

const DogEarHeading = (props: Props) => {
  return <h3 className={style.heading}>{props.children}</h3>
}

export default DogEarHeading
