import { StaticImage } from 'gatsby-plugin-image'
import * as style from './MeritSection.module.scss'
import DogEarHeading from './components/DogEarHeading'

const MeritSection = () => {
  return (
    <section className={style.container}>
      <div className={style.inner}>
        <DogEarHeading>外国人を採用するメリット</DogEarHeading>
        <div className={style.boxes}>
          <div className={style.box}>
            <StaticImage
              className={style.img}
              objectFit="contain"
              alt="mv"
              src="../../../assets/img/vietnam/merit_1.png"
              quality={100}
              width={226}
            />
            <h4 className={style.title}>労働力の確保</h4>
            <p className={style.paragraph}>
              15歳～64歳の労働力の中心となる生産年齢人口の減少が著しく、若い世代の雇用は企業間での競争が激化。人手不足の問題は今後ますます拡大すると考えられている中、人手不足を解消する鍵は外国人材になると考えられています。
            </p>
          </div>
          <div className={style.box}>
            <StaticImage
              className={style.img}
              alt="mv"
              objectFit="contain"
              src="../../../assets/img/vietnam/merit_2.png"
              quality={100}
              width={226}
            />
            <h4 className={style.title}>若くて優秀な人材の確保</h4>
            <p className={style.paragraph}>
              もっとも習得が難しい言語とされる日本語を習得した外国人材は、優秀でバイタリティーに溢れる傾向にあります。知識や技術の吸収を目的としていることから、学ぶ姿勢が真摯で企業にとって強力な人材となります。
            </p>
          </div>
          <div className={style.box}>
            <StaticImage
              className={style.img}
              objectFit="contain"
              alt="mv"
              src="../../../assets/img/vietnam/merit_3.png"
              quality={100}
              width={226}
            />
            <h4 className={style.title}>企業の活性化</h4>
            <p className={style.paragraph}>
              異なる価値観を持った外国人材は、今まで疑問
              に思うことのなかった業務の効率化ができるなど、変化をもたらしてくれる存在です。異文化アイデアを高品質な日本の開発力と繋げ、幅広いメリットが期待できます。
            </p>
          </div>
          <div className={style.box}>
            <StaticImage
              className={style.img}
              objectFit="contain"
              alt="mv"
              src="../../../assets/img/vietnam/merit_4.png"
              quality={100}
              width={226}
            />
            <h4 className={style.title}>従事できる業務内容の 範囲が広い</h4>
            <p className={style.paragraph}>
              通訳やSE、建築設計、食品加工工場、農業、ホテル、ビル清掃など、その他様々な業種にて就業が可能となっています。
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MeritSection
