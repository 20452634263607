//import * as style from './VietnamPage.module.scss'
import MainVisualSection from './MainVisualSection'
import StrengthsSection from './StrengthsSection'
import WhatWeCanDoSection from './WhatWeCanDoSection'
import WhatSkillAndMeritSection from './WhatSkillAndMeritSection'
import UsageFlowSection from './UsageFlowSection'
import FAQSection from './FAQSection'
import WPTemplate from '@/components/templates/WPTemplate'

const VietnamPage = () => {
  return (
    <WPTemplate>
      <div>
        <MainVisualSection />
        <StrengthsSection />
        <WhatWeCanDoSection />
        <WhatSkillAndMeritSection />
        <UsageFlowSection />
        <FAQSection />
      </div>
    </WPTemplate>
  )
}

export default VietnamPage
